import React, { useMemo, useState } from 'react'
import { FloatingDelayGroup } from '@floating-ui/react'
import { FireIcon, HeartIcon } from '@heroicons/react/outline'
import { LockClosedIcon } from '@heroicons/react/solid'
import { Button, Modal } from '@mantine/core'
import { LayoutGroup, motion } from 'framer-motion'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { InstagramIcon } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { Link, useLocation } from 'react-router-dom'
import {
  Announcement01,
  ArrowRight,
  BarChartSquare02,
  InfoCircle,
  Map01,
  PlayCircle,
  Star01,
  Stars02,
  Users01,
} from 'untitled-icons'

import { IS_DEV } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { classNames } from '@/util/classNames'
import { SaveFilterPrompt } from '../market-insights/_components/SaveFilterPrompt'
import s from './tab.module.css'
import { HoverButton, Tooltip, TooltipContent, TooltipTrigger, useTooltipState } from './Tooltip'

const MotionLink = motion(Link)
const MotionSaveFilterPrompt = motion(SaveFilterPrompt)

export const indicatorAtom = atomWithStorage('dot-indicator-v2', {
  consumer: ['/instagram'],
})

const variants = {
  hidden: {
    x: -5,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
}

const SubItem: React.FunctionComponent<
  React.PropsWithChildren<{
    href?: string
    disabled?: boolean
    locked?: boolean
    icon: React.ReactNode
    title: string
    subTitle: string
    currentPath?: string
    prompt?: boolean
    isNew?: boolean
    onClick?: () => void
  }>
> = ({ href, icon, title, subTitle, disabled, locked, isNew, currentPath, prompt, onClick }) => {
  disabled = disabled || locked
  const state = useTooltipState()
  const posthog = usePostHog()
  const { pathname } = useLocation()
  const isActivePath = href === pathname
  href = disabled ? undefined : href
  const isExternalLink =
    href?.startsWith('http://') || href?.startsWith('https://') || href?.startsWith('www.')
  const Component = useMemo(
    () =>
      disabled
        ? ({ children }) => (
            <div className="block w-full rounded-md px-3 py-2 text-left">{children}</div>
          )
        : prompt
        ? ({ children }) => (
            <MotionSaveFilterPrompt
              to={(href || '#') + location.search}
              className={classNames(
                'block w-full cursor-pointer rounded-md px-3 py-2 text-left transition-colors duration-75',
                isActivePath ? 'bg-gray-200' : 'hover:bg-gray-100' // <-- conditionally apply bg based on active path
              )}
              onClick={() => {
                onClick?.()
                posthog.capture('NavBar Item Clicked', { title })
              }}
            >
              {children}
            </MotionSaveFilterPrompt>
          )
        : ({ children }) => (
            <MotionLink
              to={(href || '#') + location.search}
              target={isExternalLink ? '_blank' : undefined} // Add these
              rel={isExternalLink ? 'noopener noreferrer' : undefined}
              className={classNames(
                'block cursor-pointer rounded-md px-3 py-2 transition-colors duration-75',
                isActivePath ? 'bg-gray-200' : 'hover:bg-gray-100' // <-- conditionally apply bg based on active path
              )}
              // initial="hidden"
              // animate="show"
              layout="position"
              variants={variants}
              onClick={() => {
                onClick?.()
                posthog.capture('NavBar Item Clicked', { title })
                state.setOpen(false)
              }}
            >
              {children}
            </MotionLink>
          ),
    [disabled, prompt, href, isActivePath, onClick, posthog, title, isExternalLink, state]
  )
  return (
    <Component>
      <div className="flex items-start space-x-2">
        <div className={classNames('mt-0.5 size-5 text-gray-900', disabled && 'text-gray-500')}>
          {icon}
        </div>
        <div className="w-full">
          <div
            className={classNames(
              'flex items-center space-x-2 text-sm font-semibold text-gray-900',
              disabled && 'text-gray-500'
            )}
          >
            <p>{title}</p>
            {isNew && (
              <div className="rounded-md bg-accent-600 px-1.5 text-xxs font-normal text-white">
                NEW
              </div>
            )}
            {locked && <LockClosedIcon className="size-4" />}
          </div>
          <p className={classNames('mt-1 text-xs text-gray-600', disabled && 'text-gray-500')}>
            {subTitle}
          </p>
        </div>
      </div>
    </Component>
  )
}

const VideoItem: React.FunctionComponent<
  React.PropsWithChildren<{
    onClick?: () => void
    thumbnail: string
    title: string
    subTitle: string
  }>
> = ({ onClick, thumbnail, title, subTitle }) => {
  return (
    <motion.button
      //   href={href || '#'}
      className="block w-full cursor-pointer rounded-md p-2 text-left hover:bg-white"
      // initial="hidden"
      // animate="show"
      layout="position"
      variants={variants}
      onClick={() => onClick && onClick()}
    >
      <div className="flex items-start space-x-4">
        <img
          alt="thumbnail"
          style={{ width: 200, objectFit: 'contain' }}
          className="rounded-md"
          src={thumbnail}
        ></img>
        <div className="w-full">
          <p className="text-sm font-semibold text-gray-900 ">{title}</p>

          <p className="mb-3 mt-1 text-xs text-gray-600">{subTitle}</p>
          <div className="flex items-center space-x-2">
            <PlayCircle className="size-4 text-gray-900 " />
            <p className="text-sm font-semibold text-gray-900">Watch Video</p>
          </div>
        </div>
      </div>
    </motion.button>
  )
}

export const Container: React.FunctionComponent<
  React.PropsWithChildren<{
    title?: React.ReactNode
    links: React.ReactNode
    videos?: React.ReactNode
    videosLink?: string
  }>
> = ({ title, links, videos, videosLink }) => {
  return (
    <div className={videos ? 'grid w-[800px] grid-cols-5' : 'w-[320px]'}>
      <div className="col-span-2 p-4">
        {title && <p className="mb-2 ml-2 text-sm font-semibold text-gray-900">{title}</p>}
        <div className="space-y-1">{links}</div>
      </div>
      {videos && (
        <div className="col-span-3 space-y-2 bg-gray-100 p-4 !pb-3">
          <p className="ml-2 text-sm font-semibold text-gray-900">Education Center</p>
          <div>{videos}</div>
          <Button
            variant="subtle"
            component="a"
            href={videosLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>All video tutorials</p> <ArrowRight className="size-4" />
          </Button>
        </div>
      )}
    </div>
  )
}

export const IndicatorDot = ({ section }: { section: string }) => {
  const [indicator, setIndicator] = useAtom(indicatorAtom)
  return (
    indicator[section] &&
    indicator[section].length > 0 && (
      <div className="absolute right-2.5 top-1.5 size-2 rounded-full bg-accent-600"></div>
    )
  )
}

export const NavBar = () => {
  const { user } = useAuth()
  const { pathname } = useLocation()
  const [href, setHref] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const isEcomActive =
    pathname.startsWith('/market-insights') ||
    pathname.startsWith('/product-insights') ||
    pathname.startsWith('/product-analysis') ||
    pathname.startsWith('/rankings') ||
    pathname.startsWith('/review-insights')

  const [indicator, setIndicator] = useAtom(indicatorAtom)

  return (
    <div className={s['tabs-wrapper']}>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        size="800px"
        padding={0}
        withCloseButton={false}
        centered
        withinPortal
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video width={800} height="auto" controls>
          <source src={href} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
      <div className={classNames('flex items-center', s.tabs)}>
        <FloatingDelayGroup delay={{ open: 200, close: 200 }} timeoutMs={200}>
          <LayoutGroup>
            <HoverButton to="/" isActive={pathname === '/'}>
              Home
            </HoverButton>
            <Tooltip>
              <TooltipTrigger
                isActive={
                  pathname.startsWith('/consumer-insights') ||
                  pathname.startsWith('/discovery') ||
                  pathname.startsWith('/review-insights') ||
                  pathname.startsWith('/instagram')
                }
              >
                <IndicatorDot section="consumer" />
                <p>Consumer</p>
              </TooltipTrigger>
              <TooltipContent>
                <Container
                  title={'Consumer'}
                  links={
                    <>
                      <SubItem
                        href="/discovery"
                        icon={<Map01 />}
                        title="Discovery Page"
                        subTitle="Uncover which topics are currently in high demand among consumers and which ones are on the rise."
                        currentPath={pathname}
                      />
                      <SubItem
                        href="/consumer-insights"
                        icon={<Users01 />}
                        title="Consumer Insights"
                        subTitle="Get to know your consumers and their behaviour on Google and TikTok."
                        currentPath={pathname}
                        locked={user?.isFree}
                      />
                      <SubItem
                        href="/review-insights"
                        icon={<Star01 />}
                        title="Review Insights"
                        subTitle="Analyse who the reviewers are and what they are saying in their reviews."
                        currentPath={pathname}
                      />
                      <SubItem
                        href="/instagram"
                        icon={<InstagramIcon className="size-4" />}
                        title="Instagram"
                        subTitle="Explore posts highlighting effects, ingredients, and more in their captions."
                        currentPath={pathname}
                        isNew
                        onClick={() =>
                          setIndicator((prev) => ({
                            ...prev,
                            consumer: prev['consumer'].filter((i) => i !== '/instagram'),
                          }))
                        }
                      />
                      {IS_DEV && (
                        <>
                          <SubItem
                            href="/consumer-insights-v2/trends"
                            icon={<Users01 />}
                            title="Consumer Insights v2"
                            subTitle="Understand your consumers by exploring their behavior on Google, and discover content they engage with on Instagram and TikTok."
                            currentPath={pathname}
                            locked={user?.isFree}
                          />
                          <SubItem
                            href="/social-media"
                            icon={<HeartIcon className="size-4" />}
                            title="Social Media"
                            subTitle="Discover the latest and most engaging posts from influencers and brands on Instagram and TikTok."
                            currentPath={pathname}
                          />
                        </>
                      )}
                    </>
                  }
                />
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger
                isActive={
                  pathname.startsWith('/market-insights') || pathname.startsWith('/new-products')
                }
              >
                <IndicatorDot section="market" />
                <p>Market</p>
              </TooltipTrigger>
              <TooltipContent>
                <Container
                  title={'Market'}
                  links={
                    <>
                      <SubItem
                        href="/market-insights"
                        icon={<BarChartSquare02 />}
                        title="Market Insights"
                        subTitle="Get to know your market."
                        currentPath={pathname}
                        prompt={isEcomActive}
                      />
                      <SubItem
                        href="/new-products"
                        icon={<FireIcon />}
                        title="Trending Combinations"
                        subTitle="Combinations of attributes, effects, concerns, or certificates with a product type."
                        currentPath={pathname}
                        isNew
                      />
                    </>
                  }
                  videos={
                    <>
                      <VideoItem
                        thumbnail={'/navbar/webp/thumbnail-02.webp'}
                        title="Tutorial: Market Insights"
                        subTitle="Watch the tutorial to learn how to conduct market analysis with eyva easily."
                        onClick={() => {
                          setHref(
                            'https://eyva-reports.s3.eu-central-1.amazonaws.com/MI+Use+Case+final.mp4'
                          )
                          setModalOpen(true)
                        }}
                      />
                    </>
                  }
                  videosLink="https://eyva.notion.site/Use-Case-Videos-5feead2411b142df957dee1b3aee7454?pvs=4"
                />
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger
                isActive={
                  pathname.startsWith('/product-insights') ||
                  pathname.startsWith('/product-analysis') ||
                  pathname.startsWith('/rankings')
                }
              >
                Product
              </TooltipTrigger>
              <TooltipContent>
                {user?.isFree ? (
                  <div className="w-[570px] space-y-3 px-5 py-4 text-xs">
                    <h3 className="text-sm font-semibold">Product </h3>
                    <p>
                      Market & Product Feature{' '}
                      <span className="font-semibold">
                        simplifies market analysis and product benchmarking with its user-friendly
                        design
                      </span>{' '}
                      and e-tailer inspired structure, ensuring easy navigation through category
                      trees and filters. This feature also enables comprehensive analysis of
                      reviews, specific products, and facilitates product comparisons effortlessly.
                      🚀
                    </p>
                    <div className="flex space-x-2">
                      <div
                        className="rounded-md bg-accent-600"
                        style={{ width: 176, height: 108 }}
                      ></div>
                      <div className="space-y-1.5">
                        <h3 className="font-medium">Features Included:</h3>
                        <p>✓ Market Insights</p> <p>✓ Product Insights</p>
                        <p>✓ Product Analysis / Comparison</p> <p>✓ Review Insights</p>
                      </div>
                    </div>
                    <p>
                      Unlocking the full spectrum of Market Insights requires an{' '}
                      <span className="font-semibold">upgrade</span> to your account, granting you
                      exclusive access to the wealth of e-commerce data. 🔥
                    </p>
                  </div>
                ) : (
                  <Container
                    title={'Product'}
                    links={
                      <>
                        <SubItem
                          href="/product-insights"
                          icon={<Stars02 />}
                          title="Product Insights"
                          subTitle="Learn all about industry benchmarks."
                          currentPath={pathname}
                          prompt={isEcomActive}
                        />
                        <SubItem
                          href="/product-analysis"
                          icon={<PlayCircle />}
                          title={'Product Catalog'}
                          subTitle="Compare products in seconds."
                          currentPath={pathname}
                          prompt={isEcomActive}
                        />
                        <SubItem
                          href="/rankings"
                          icon={<BarChartSquare02 />}
                          title="Product Performance"
                          subTitle="Identify the best-performing products on Douglas, DM and Mueller."
                          currentPath={pathname}
                          prompt={isEcomActive}
                        />
                      </>
                    }
                    videos={
                      <>
                        <VideoItem
                          thumbnail={'/navbar/webp/thumbnail-03.webp'}
                          title="Tutorial: Product Insights"
                          subTitle="Learn how to effortlessly assess product benchmarks analysis using eyva in this tutorial."
                          onClick={() => {
                            setHref(
                              'https://eyva-reports.s3.eu-central-1.amazonaws.com/PI+Use+Case+final.mp4'
                            )
                            setModalOpen(true)
                          }}
                        />
                      </>
                    }
                    videosLink="https://eyva.notion.site/Use-Case-Videos-5feead2411b142df957dee1b3aee7454?pvs=4"
                  />
                )}
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger isActive={pathname.startsWith('/pre-ingredients')}>
                Ingredients
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[337px] p-2">
                  <SubItem
                    href="/pre-ingredients"
                    icon={<BarChartSquare02 />}
                    title="Ingredient Insights"
                    subTitle="Analyse ingredients effortlessly for a deeper understanding."
                    currentPath={pathname}
                  />
                </div>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger isActive={pathname.startsWith('/request-reports')}>
                <IndicatorDot section="ai-reports" />
                <p>AI Reports</p>
              </TooltipTrigger>
              <TooltipContent>
                {user?.isFree ? (
                  <div className="w-[570px] space-y-3 px-5 py-4 text-xs">
                    <h3 className="text-sm font-semibold">Reports</h3>
                    <p>
                      eyva&apos;s Reports feature is a special tool allowing you to request and
                      receive customized reports within minutes. Select from various report types
                      and templates to drastically{' '}
                      <span className="font-semibold">
                        reduce the time spent on insights, transforming hours of research into mere
                        minutes.🚀
                      </span>
                    </p>
                    <div className="flex space-x-2">
                      <div
                        className="rounded-md bg-accent-600"
                        style={{ width: 176, height: 108 }}
                      ></div>
                      <div className="space-y-1.5"></div>
                    </div>
                    <p>
                      Accessing this feature requires an account upgrade. Elevate your eyva
                      experience and unlock premium tools by upgrading today. 🔥
                    </p>
                  </div>
                ) : (
                  <div className="w-[337px] p-2">
                    <SubItem
                      href="/request-reports"
                      icon={<BarChartSquare02 />}
                      title="Generate AI Reports"
                      subTitle="Test out pre-access to AI Reports."
                      currentPath={pathname}
                      isNew
                    />
                  </div>
                )}
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger isActive={pathname.startsWith('/magazine')}>Magazine</TooltipTrigger>
              <TooltipContent>
                <div className="w-[337px] p-2">
                  <SubItem
                    href={'/magazine'}
                    icon={<BarChartSquare02 />}
                    title="Magazine"
                    subTitle="Read latest articles from eyva."
                    currentPath={pathname}
                  />
                </div>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger isActive={pathname.startsWith('/news')}>More</TooltipTrigger>
              <TooltipContent>
                <div className="w-[337px] p-2">
                  {/* <SubItem
                    icon={<Settings01 />}
                    href="/settings"
                    title="Settings"
                    subTitle="Invite and manage team members to eyva."
                    currentPath={pathname}
                  /> */}

                  {/* <SubItem
                    icon={<Heart />}
                    title="Favorites"
                    disabled
                    subTitle="All your important data in one place. Coming soon!"
                  /> */}
                  <SubItem
                    icon={<Announcement01 />}
                    title="Updates"
                    subTitle="What is new in eyva recently? Click and discover."
                    currentPath={pathname}
                    href="/news"
                  />
                  <SubItem
                    icon={<InfoCircle />}
                    href="https://eyva.notion.site/Education-Center-95762575f3e646a28ec22fd2588ba27e?pvs=4"
                    title="Education"
                    subTitle="Find answers to frequently asked questions and tutorials for each section."
                    currentPath={pathname}
                  />
                </div>
              </TooltipContent>
            </Tooltip>
          </LayoutGroup>
        </FloatingDelayGroup>
      </div>
    </div>
  )
}
